export type NotificationType =
  'TASK_CREATE' |
  'TASK_UPDATE' |
  'TASK_INCOMPLETE' |
  'OBSERVATION_CREATE' |
  'OBSERVATION_UPDATE' |
  'ASSESSMENT_CREATE' |
  'ASSESSMENT_UPDATE' |
  'MONITORING_CREATE' |
  'MONITORING_UPDATE';

export type Rule = 'DISABLE' | 'ENABLE';
  
export interface UserNotificationPreference {
  notificationType: NotificationType;
  rule: Rule;
}

export interface NotificationSettings {
  values: UserNotificationPreference[];
}

function calcNextState(typeToChange: NotificationType, currentState: NotificationSettings | undefined) : NotificationSettings{
  const values: UserNotificationPreference[] = [];
  for(const entry of currentState?.values ?? []) {
    if(entry.notificationType === typeToChange){
      values.push({...entry, rule: entry.rule === 'DISABLE' ? 'ENABLE' : 'DISABLE'});
    } else {
      values.push(entry);
    }
  }
  return { values };
}

function calcInitState(backendState: NotificationSettings) : NotificationSettings{
  const values: UserNotificationPreference[] = [];
  for(const entry of initState.values) {
    const backendEntry = backendState.values.find(v => v.notificationType === entry.notificationType)
    if(backendEntry){
      values.push(backendEntry);
    } else {
      values.push(entry);
    }
  }
  return { values };
}

const initState: NotificationSettings = {
  values: [
    {
      notificationType: 'TASK_CREATE',
      rule: 'ENABLE',
    }, {
      notificationType: 'TASK_UPDATE',
      rule: 'ENABLE',
    }, {
      notificationType: 'TASK_INCOMPLETE',
      rule: 'ENABLE',
    }, {
      notificationType: 'OBSERVATION_CREATE',
      rule: 'ENABLE',
    }, {
      notificationType: 'OBSERVATION_UPDATE',
      rule: 'ENABLE',
    }, {
      notificationType: 'ASSESSMENT_CREATE',
      rule: 'ENABLE',
    }, {
      notificationType: 'ASSESSMENT_UPDATE',
      rule: 'ENABLE',
    }, {
      notificationType: 'MONITORING_CREATE',
      rule: 'ENABLE',
    }, {
      notificationType: 'MONITORING_UPDATE',
      rule: 'ENABLE',
    }
  ]
}

export { calcNextState, calcInitState }