import { redirect } from 'util/path';

export default () => {
  // TODO: use `<Redirect to='/signin'/>` instead. The problem with this is that you need to
  // refresh config. So a re-fetch of `/config.json` is required and then have to call
  // `setConfig(config) @client` mutation. Right now, we use the simple method of just refreshing
  // the page...

  redirect(`/signin?redirect=${window.location.pathname}`, { refresh: true });
  return null;
}
