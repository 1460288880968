import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RestLink } from 'apollo-link-rest';
import { getConfig } from 'globalConfig';
import gql from 'graphql-tag';
import fetchPatch from './fetchPatch';
import restFetchPatch from './restFetchPatch';
import clientState from './state';
import { Config } from 'types';

const cache = new InMemoryCache();
const defaults: {
	config?: Config;
} = {
  config: undefined,
};

const onErrorLink = onError(({ networkError, response, graphQLErrors }) => {
  if(networkError) {
    if(networkError.message.includes('Received status code 403')) {
      // TODO: this is such an idiotic way to check if we are getting a 403, but it seems like
      // Apollo tries to wrap and hide the real response status, so there is no "correct" way to
      // check if the response returned a 403. This must be fixed though.
      const mutation = gql`
        mutation setSessionExpired {
          setSessionExpired @client
        }
      `;
      client.mutate({ mutation });
    }
  }
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onErrorLink,
    new RestLink({
      uri: '/rest/api',
      credentials: 'include',
      customFetch: restFetchPatch,
      headers: {
        Accept: 'application/json',
      },
    }),
    new HttpLink({
      uri: '/rest/api/graphql',
      fetch: fetchPatch,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    }),
  ]),
  resolvers: clientState.resolvers,
});
cache.writeData({ data: clientState.defaults });

(async () => {
  const config = await getConfig();
  const mutation = gql`
    mutation updateConfig($config: Object) {
      updateConfig(config: $config) @client
    }
  `;

  // for some reason, when mixing @client query with regular query, it rewrites cache or
  // resolves from `defaults`. As a temporary fix, we override config key in `defaults`, so it
  // always resolves this value
  // TODO: fix this
  defaults.config = config;
  client.mutate({ mutation, variables: { config } });
})();

export default client;
