import React from 'react';
import { style } from 'typestyle';

const actionsClass = style({
  display: 'flex',
  justifyContent: 'space-between',
  margin: 10,
  height: 38,
  $nest: {
    '& > *:last-child': {
      marginLeft: 'auto',
    },
  },
});

interface Props {};
export default class PageActions extends React.PureComponent<Props> {
  render() {
    return (
      <div className={actionsClass}>
        { this.props.children }
      </div>
    );
  }
}
