import React from 'react';
import { style } from 'typestyle';

const headingClass = style({
  fontSize: 18,
});

interface Props {};
export default class PageSubheading extends React.PureComponent<Props> {
  render() {
    return (
      <div className={headingClass}>
        { this.props.children }
      </div>
    );
  }
}
