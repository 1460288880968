import React, { ErrorInfo, PropsWithChildren } from "react";
import * as csx from 'csx';
import { FormattedMessage } from 'react-intl';
import { style } from 'typestyle';

export interface ErrorRecorder {
  recordError(error: any): void;
}

interface State {
  hasError: boolean;
  pathname?: string;
};


export interface ErrorBoundaryProps {
  errorRecorder: ErrorRecorder;
}

const errorClass = style({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  $nest: {
    '& > *': {
      width: csx.percent(50),
      fontSize: 23,
    },
  },
});

export class ErrorBoundary extends React.Component<PropsWithChildren<ErrorBoundaryProps>, State> {
  state: State = {
    hasError: false,
  };

  constructor(props: ErrorBoundaryProps) {
    super(props);
  }


  // static getDerivedStateFromProps(props: any, state: State) {
  //   if (state.hasError) {
  //     return { hasError: false };
  //   }
  //   return null;
  // }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.info("Record an error!");
    this.props.errorRecorder.recordError(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={errorClass}>
          <FormattedMessage
            id='error.handled-automatically'
            defaultMessage={`Sorry, an unexpected error occurred. This has been automatically
            reported to the system administrators. You can go back or try refreshing this page.`}
          />
        </div>
      );
    }
    return this.props.children;
  }
}
