import { Permission } from 'types';
import { StateContainer } from './state';

interface State {
  permissions: Permission[],
}

const permissions: StateContainer<State> = {
  defaults: {
    permissions: [],
  },
  resolvers: {
    Mutation: {
      setPermissions: (_, { permissions }, { cache }) => {
        const data = {
          permissions: permissions.map((permission: any) => {
            return { ...permission, __typename: 'Permission' };
          }),
        };

        cache.writeData({ data });
        return null;
      },
    },
  },
};

export default permissions;
