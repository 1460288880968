const baseUrl = window.location.origin;
const restUrl = `${baseUrl}/rest/api`;
const graphqlPath = '/graphql';
const graphqlUrl = `${restUrl}${graphqlPath}`;
const basename = '/app';
const fullPathname = window.location.pathname.replace(`${basename}/`, '/');
const tenantHeader = 'RH-Tenant';
const tenantAll = 'all';
const requestAsHeader = 'RH-As';
const requestAsTenantAdmin = 'TENANT_ADMIN';
const requestAsUser = 'USER';

const WRENCH_ALL = 9000;

export {
  restUrl,
  graphqlUrl,
  graphqlPath,
  baseUrl,
  basename,
  fullPathname,
  tenantHeader,
  tenantAll,
  requestAsHeader,
  requestAsTenantAdmin,
  requestAsUser,
  WRENCH_ALL
};
