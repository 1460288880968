import produce from 'immer';
import React, { useCallback, useState } from 'react';
import { Options } from 'util/restFetch';

interface Cache {
  [url: string]: undefined | {
    [options: string]: unknown;
  };
}
type Get = (url: string, options: Options) => any;
type Put = (url: string, options: Options, result: any) => void;
type Remove = (url: string, options: Options) => void;
interface Context { get: Get, put: Put, remove: Remove };

const stringifyOptions = JSON.stringify;
export const QueryContext = React.createContext<Context>({
  get: () => {},
  put: () => {},
  remove: () => {},
});
export const QueryProvider: React.FC<{}> = ({ children }) => {
  const [queryCache, setQueryCache] = useState<Cache>({});

  const get: Get = useCallback((url, options) => {
    const urlData = queryCache[url];
    if(!urlData) return undefined;
    return urlData[stringifyOptions(options)];
  }, [queryCache]);

  const put: Put = useCallback((url, options, result) => {
    setQueryCache(produce(queryCache, queryCache => {
      const urlData = queryCache[url];
      if(!urlData) {
        queryCache[url] = {
          [stringifyOptions(options)]: result,
        };
      } else {
        urlData[stringifyOptions(options)] = result;
      }
    }))
  }, [queryCache]);

  const remove: Remove = useCallback((url, options) => {
    setQueryCache(produce(queryCache, queryCache => {
      const urlData = queryCache[url];
      if(!urlData) return;

      delete urlData[stringifyOptions(options)];
    }))
  }, [queryCache]);

  return (
    <QueryContext.Provider value={{ get, put, remove }}>
      { children }
    </QueryContext.Provider>
  );
}
