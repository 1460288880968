import classNames from 'classnames';
import Responsive from 'components/Responsive';
import * as csx from 'csx';
import React from 'react';
import { media, style } from 'typestyle';

const footerClass = style(
  media({ maxWidth: Responsive.onlyMobile.maxWidth }, {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    $nest: {
      '& button.opux-btn': {
        marginTop: csx.important('10px'),
        marginLeft: 0,
      },
    },
  }),
  media({ minWidth: (Responsive.onlyMobile.maxWidth || 0) + 1 }, {
    $nest: {
      '& button.opux-btn': {
        marginLeft: 10,
      },
    },
  })
);

interface ModalFooterProps {};
export default class ModalFooter extends React.PureComponent<ModalFooterProps> {
  render() {
    const fullClassName = classNames('opux-modal-footer', footerClass);
    return (
      <div className={fullClassName}>
        { this.props.children }
      </div>
    )
  }
}
