import React from 'react';
import PageActions from './PageActions';
import PageFilters from './PageFilters';
import PageHeading from './PageHeading';
import PageIcons from './PageIcons';
import PageSubheading from './PageSubheading';

interface Props {};
export default class Page extends React.PureComponent<Props> {
  static Actions = PageActions;
  static Heading = PageHeading;
  static Subheading = PageSubheading;
  static Filters = PageFilters;
  static Icons = PageIcons;

  render() {
    return this.props.children;
  }
}
