import { AwsRum } from 'aws-rum-web';

export interface ErrorRecorder {
  recordError(error: any): void;
}

export const awsRum = (config?: any): ErrorRecorder => {
  if (config?.rum?.enabled) {
    try {
      return new AwsRum(
        config.rum.applicationId,
        config.rum.applicationVersion ?? '0.0.0',
        config.rum.applicationRegion,
        config.rum.config
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }
  return {
    recordError: (error: any) => {
      console.log(error);
    }
  };
}
