import Indicator from 'components/Indicator';
import ValueSelect from 'components/ValueSelect';
import { Colors } from 'opux/styles';
import React from 'react';
import { defineMessages, FormattedMessage, Messages } from 'react-intl';

export const messages: Messages = defineMessages({
  DISABLE: {
    id: 'email.settings.disabled',
    defaultMessage: 'Disabled',
  },
  ENABLE: {
    id: 'email.settings.enabled',
    defaultMessage: 'Enabled',
  },
});

type Flags = 'ENABLE' | 'DISABLE';

const colorMap: { [K in Flags]: keyof Colors } = {
  DISABLE: 'blue',
  ENABLE: 'orange',
};

interface Props {
  value: Flags;
  onSelect?: (value: Flags | null) => any;
  withLabel?: boolean;
  circular?: boolean;
}

export default class EnableDisable extends React.PureComponent<Props> {
  render() {
    const { value, onSelect, circular, ...restProps } = this.props;

    if(onSelect) {
      return (
        <ValueSelect
          onChange={onSelect}
          valueColor={colorMap}
          selected={value}
        >
          {optionPriority => <FormattedMessage {...messages[optionPriority]}/>}
        </ValueSelect>
      );
    }

    return (
      <Indicator color={colorMap[value]} circular={circular} {...restProps}>
        {!circular && <FormattedMessage {...messages[value]}/>}
      </Indicator>
    );
  }
}
