import { Grid } from '@resys/opux-react';
import React from 'react';
import { style } from 'typestyle';
import PageSubheading from './PageSubheading';

const headingClass = style({
  marginBottom: 18,
});

interface Props {};
export default class PageHeading extends React.PureComponent<Props> {
  render() {
    const filteredChildren: any[] = [];
    let subheading: React.ReactNode = null;

    React.Children.forEach(this.props.children, (child: any) => {
      if(child.type === PageSubheading) {
        subheading = child;
      } else {
        filteredChildren.push(child);
      }
    });

    return (
      <Grid.Row className={headingClass}>
        <Grid.Column extraSmall={12} medium={12} className='portlet-layout'>
          <div className='op-layout-heading'>
            <div className='op-h1-wrapper'>
              <h1 className='op-content-tile-title'>
                {filteredChildren}
              </h1>
              {subheading}
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  }
}
