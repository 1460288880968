import { Alert } from '@resys/opux-react';
import * as csx from 'csx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { style } from 'typestyle';

const appUpdateMessageClass = style({
  position: 'fixed',
  left: 0,
  bottom: 0,
  marginBottom: 0,
  zIndex: 1200,
  width: csx.percent(100),
});

const AppUpdateMessage: React.FC<{}> = () => (
  <Alert notification className={appUpdateMessageClass}>
    <strong>
      <FormattedMessage
        id='app.update-pending'
        defaultMessage='Application has been updated! Please reload the page'
      />
    </strong>
  </Alert>
);

export default AppUpdateMessage;
