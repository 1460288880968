import classNames from 'classnames';
import React from 'react';

interface ItemProps {
  hasChildren?: boolean;
  to?: string;
  title?: string;
  as?: React.ComponentType<any>;
  children?: React.ReactNode;
};
const Item = (props: ItemProps) => {
  const { hasChildren, to, title, as: GivenComponent, children, ...restProps } = props;
  const fullClassName = classNames(
    'op-navi-item',
    'op-navi-link-style-normal',
    'op-navi-level-open',
    {
      'op-navi-item-has-children': hasChildren,
    }
  );

  const Component = GivenComponent || (() => (
    <a href={to}>
      <span className='op-navi-text'>
        { title }
      </span>
    </a>
  ));

  return (
    <li className={fullClassName}>
      <div className='op-navi-link-row'>
        <Component className='op-navi-link' {...restProps}/>
      </div>
      { children }
    </li>
  )
};

interface LeftSidebarBodyProps {
  signedIn: boolean;
};
export class LeftSidebarBody extends React.PureComponent<LeftSidebarBodyProps> {
  static Item = Item;

  render() {
    const { signedIn } = this.props;

    const fullClassName = classNames(
      'op-navigation',
      {
        'op-navigation-signed-in': signedIn,
        'op-navigation-signed-out': !signedIn,
      }
    );

    return (
      <div className={classNames(
        'portlet-boundary',
        'portlet-static',
        'portlet-static-end',
        'portlet-borderless',
        'portlet-position-0'
      )}>
        <div className='portlet-borderless-container'>
          <div className='portlet-body'>
            <nav id='op-main-navigation' className={fullClassName} role='navigation'>
              <ul
                className='op-navi-level op-navi-level-1 op-navi-list'
                role='tree'
                aria-expanded='false'
              >
                { this.props.children }
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
