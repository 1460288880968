import memoize from 'util/memoize';

/**
 * Sets an item to the specified key in the storage layer.
 * @param key Unique key for the data that is to be stored
 * @param data Data to store
 */
export function set<T>(key: string, data: T) {
  if(typeof data === 'string') {
    localStorage.setItem(key, data);
  } else {
    localStorage.setItem(key, JSON.stringify(data));
  }
}

/**
 * Gets an item from the storage layer. Note that this operation might be expensive, depending
 * on the storage layer used by the implementation. If fast execution is important, use `getCached`.
 * @param key Unique key of the item that is to be retrieved
 */
export function get<T>(key: string): T | undefined {
  const rawData = localStorage.getItem(key);

  if(rawData === null) {
    return undefined;
  }

  try {
    const data = JSON.parse(rawData);
    return data as T;
  } catch(e) {
    return (rawData as unknown) as T;
  }
}

export const getCached = memoize((key: string) => get(key)) as typeof get;
