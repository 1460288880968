import { basename } from 'const';

export function currentPath() {
  return window.location.pathname.slice(basename.length);
}

interface RedirectOptions {
  refresh?: boolean;
  replace?: boolean;
}
export function redirect(url: string, options?: RedirectOptions) {
  if(!url.startsWith('/')) {
    url = '/' + url;
  }

  url = basename + url;
  if(options && options.refresh) {
    window.location.href = url;
  } else if(options && options.replace) {
    window.history.replaceState(null, url, url);
  } else {
    window.history.pushState(null, url, url);
  }
}

export function redirectFromSignin(options?: RedirectOptions) {
  const currentUrl = new URL(window.location.toString());
  let redirectUrl = currentUrl.searchParams.get('redirect');
  if(redirectUrl && new URL(redirectUrl, currentUrl.origin).host === currentUrl.host) {
    redirectUrl = redirectUrl.substr(basename.length);
    redirect(redirectUrl, options);
  } else {
    redirect('/', options);
  }
}
