import { Button as OpuxButton, ButtonProps as OpuxButtonProps } from '@resys/opux-react';
import classNames from 'classnames';
import React from 'react';

export interface ButtonProps extends OpuxButtonProps {
  fluid?: boolean;
}
const Button: React.FC<ButtonProps> = ({ className, fluid, ...restProps }) => {
  const fullClassName = classNames(className, {
    'fluid': fluid,
  });

  return <OpuxButton className={fullClassName} {...restProps}/>
}

export default Button;
