import memoize from 'util/memoize'
import { FlatPermission, MappedPermissions } from './permissionTypes';

export const flatPermissionsToMap = memoize((flatPermissions: FlatPermission[]) => {
  const resultMap: MappedPermissions = {};

  for(const permission of flatPermissions) {
    if(!resultMap[permission.resource]) resultMap[permission.resource] = {};

    resultMap[permission.resource][permission.locationId] = {
      create: permission.createPermission,
      update: permission.updatePermission,
      deleteAll: permission.deleteAllPermission,
      deleteSelf: permission.deleteSelfPermission,
    };
  }

  return resultMap;
});
