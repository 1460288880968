import classNames from 'classnames';
import Responsive from 'components/Responsive';
import React from 'react';
import { LeftSidebarBody } from './LeftSidebarBody';
import { LeftSidebarLogo } from './LeftSidebarLogo';

interface LeftSidebarState {
  open: boolean;
  width: number;
};
interface LeftSidebarProps {
  topbarOpen?: boolean;
  followScroll?: boolean;
  onClick?: () => void;
};
export class LeftSidebar extends React.Component<LeftSidebarProps, LeftSidebarState> {
  static Logo = LeftSidebarLogo;
  static Body = LeftSidebarBody;
  static breakpoint = Responsive.onlyTablet.maxWidth;

  state: LeftSidebarState = {
    open: false,
    width: window.screen.width,
  };

  handleClick = () => {
    const { width } = this.state;
    const { onClick } = this.props;
    if (LeftSidebar.breakpoint && width < LeftSidebar.breakpoint) {
      this.setState({ open: !this.state.open });
    }

    if(onClick) {
      onClick();
    }
  }

  updateScreenSize = (width: number) => {
    const { width: oldWidth } = this.state;

    this.setState({ width });

    if(!LeftSidebar.breakpoint) return;
    if(oldWidth >= LeftSidebar.breakpoint && width < LeftSidebar.breakpoint) {
      this.setState({ open: false });
    } else if(oldWidth < LeftSidebar.breakpoint && width >= LeftSidebar.breakpoint) {
      this.setState({ open: true });
    }
  }

  render() {
    const { open } = this.state;
    const { topbarOpen, followScroll } = this.props;

    const fullClassName = classNames(
      'op-sidebar',
      {
        'op-sidebar-open': open,
        'op-topbar-sidebar-closed': !topbarOpen,
        'op-topbar-sidebar-open': topbarOpen,
      }
    );
    return (
      <aside
        id='op-left-sidebar'
        className={fullClassName}
        tabIndex={0}
        aria-expanded={open ? 'true' : 'false'}
        onClick={this.handleClick}
      >
        <Responsive onUpdate={this.updateScreenSize}/>
        <div className={classNames('op-sidebar-wrapper', { 'scroll-follow': followScroll })}>
          <div className='op-sidebar-content'>
            { this.props.children }
          </div>
        </div>
        <div className={classNames('op-sidebar-toggle')}>
          <a aria-controls='op-left-sidebar' role='button' aria-label='Expand left side bar'>
            <span className='op-sidebar-collapsed-cover'>
              <div className={classNames(
                'op-sidebar-toggle-hamburger',
                'opux-icon',
                'opux-icon-circled',
                'opux-icon-list'
              )}/>
            </span>
          </a>
        </div>
      </aside>
    );
  }
}
