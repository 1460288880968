import { StateContainer } from './state';

interface Data {
  session: {
    expired: boolean;
    __typename: string;
  }
};

const debugBar: StateContainer<Data> = {
  defaults: {
    session: {
      expired: false,
      __typename: 'session',
    },
  },
  resolvers: {
    Mutation: {
      setSessionExpired(_, _args, { cache }) {
        cache.writeData({
          data: {
            session: {
              expired: true,
              __typename: 'session',
            },
          },
        });
        return null;
      },
    },
  },
};

export default debugBar;
