import { appendAnonContext } from 'apollo/fetchPatch';
import { Config } from 'types';
import isAnonymous from 'util/isAnonymous';
import memoize from 'util/memoize';
import { redirect, redirectFromSignin } from 'util/path';

function redirectToSignin(): void {
  const pathname = window.location.pathname;
  if(pathname.startsWith('/app/signin')) {
    return;
  }

  redirect(`/signin?redirect=${pathname}`, { replace: true });
}

function redirectToApp(): void {
  const pathname = window.location.pathname;
  if(!pathname.startsWith('/app/signin')) return;
  redirectFromSignin({ replace: true });
}

function fetchConfig(anonContext: boolean = true) {
  let url;
  if(anonContext) {
    url = appendAnonContext('/config.json');
  } else {
    url = '/config.json';
  }

  return fetch(url, {
    redirect: 'manual',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  });
}

// TODO: this is a hack to avoid injecting Apollo Client into modules that just need access to
// the global config. Whether this should be kept or replaced with a "nicer" solution,
// should be re-considered at a later point in time.
const getConfig = memoize(async () => {
  let pathname = window.location.pathname;
  if(pathname.startsWith('/o/')) {
    const splitUrl = pathname.split('/');
    const linkId = splitUrl[splitUrl.length - 1];
    redirect(`/anon/observations?a=${linkId}`, { replace: true });
    pathname = window.location.pathname;
  } else if(!pathname.startsWith('/app/')) {
    redirect('/', { replace: true });
  }

  try {
    let response = await fetchConfig();
    if(!response.ok && isAnonymous()) {
      redirect(appendAnonContext('/anon/error'), { replace: true });
      response = await fetchConfig(false);
    }

    if (response.redirected) {
      redirectToSignin();
      return undefined;
    }
    const json: Config = await response.json();

    if (!pathname.startsWith('/app/anon')) {
      if (!json.userHref) {
        redirectToSignin();
      } else {
        redirectToApp();
      }
    }

    return json;
  } catch (e) {
    redirectToSignin();
  }
  return undefined;
});

export { getConfig };
