import { Link, List, Tooltip } from '@resys/opux-react';
import Icon from 'components/Icon';
import LeftSidebar from 'components/LeftSidebar';
import LogoutModal from 'components/LogoutModal';
import Responsive from 'components/Responsive';
import { Item } from 'components/RightSidebar/RightSidebar';
import { LocaleContext } from 'context/locale-provider';
import { Topbar as OpuxTopbar } from 'opux/portal/Topbar';
import React from 'react';
import { Locale } from 'types';
import { style } from 'typestyle';

const iconClass = style({
  fontSize: 36,
});

interface State {
  logoutModalOpen: boolean;
  sidebarOpen: boolean;
};
interface Props {};
export default class Topbar extends React.PureComponent<Props, State> {
  state: State = {
    logoutModalOpen: false,
    sidebarOpen: false,
  }
  languageSelectFields = new Set([Item.LanguageSelect]);

  openLogout = () => {
    this.setState({ logoutModalOpen: true });
  }

  closeLogout = () => {
    this.setState({ logoutModalOpen: false });
  }

  toggleLeftSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  render() {
    const { logoutModalOpen, sidebarOpen } = this.state;

    return (
      <>
        <LeftSidebar
          followScroll
          topbarOpen={sidebarOpen}
          onClick={this.toggleLeftSidebar}
        />
        {logoutModalOpen && <LogoutModal onClose={this.closeLogout}/>}
        <OpuxTopbar>
          <LocaleContext.Consumer>
            {({ selectedLocale, changeLocale }) => (
              <OpuxTopbar.Item name='language'>
                <Tooltip
                  on='click'
                  trigger={(
                    <span className='op-topbar-language-wrapper'>
                      {selectedLocale}
                    </span>
                  )}
                  position='bottom'
                >
                  <List action>
                    {['fi', 'sv', 'en'].map(locale => (
                      <List.Item>
                        <Link action onClick={() => changeLocale(locale as Locale)}>
                          {locale.toUpperCase()}
                        </Link>
                      </List.Item>
                    ))}
                  </List>
                </Tooltip>
              </OpuxTopbar.Item>
            )}
          </LocaleContext.Consumer>
          <OpuxTopbar.Item onClick={this.openLogout}>
            <span><Icon name='lock' circled className={iconClass}/></span>
          </OpuxTopbar.Item>
          <Responsive {...Responsive.onlyMobile}>
            <OpuxTopbar.Item onClick={this.toggleLeftSidebar}>
              <span><Icon name='list' circled className={iconClass}/></span>
            </OpuxTopbar.Item>
          </Responsive>
        </OpuxTopbar>
      </>
    );
  }
}
