import { OpuxIconName } from '@resys/opux-react';
import Icon from 'components/Icon';
import { LeftSidebar } from 'opux/portal/LeftSidebar';
import React from 'react';

interface Props {
  shown: boolean;
  icon: OpuxIconName;
  onClick?: () => void;
};
export default class RightSidebarItem extends React.PureComponent<Props> {
  render() {
    const { shown, onClick, icon, children } = this.props;
    if(!shown) return null;
    return (
      <LeftSidebar.Body.Item as={(props) => (
        <a onClick={onClick} {...props}>
          <Icon name={icon} circled/>
          <span className='op-navi-text'>
            { children }
          </span>
        </a>
      )}/>
    );
  }
}
