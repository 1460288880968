import { Permission } from 'types';
import memoize from 'util/memoize';
import { ActionPermission, MappedPermissions } from './permissionTypes';

interface Options {
  reloadIfNoLocationAccess?: boolean;
  deleteOwner?: number;
}

const buildPermissions = memoize((
  isAdmin: boolean,
  isSystemAdmin: boolean,
  isTenantAdmin: boolean,
  isAnonymous: boolean,
  permissions: MappedPermissions,
  userId: number | null
) => {
  return {
    isAdmin,
    isSystemAdmin,
    isTenantAdmin,
    isAnonymous,
    /**
     * Returns true if user has the given permission for given resource under given location.
     * If `locationId` is `null`, then it will be checked against a global resource.
     * @param resource {string}
     * @param locationId {number}
     * @param permission {Permission}
     * @param options {Options}
     */
    exists: (
      resource: string,
      locationId: number | string | null,
      permission: Permission,
      options: Options = {}
    ) => {
      if(isAnonymous) return false;
      if(isAdmin) return true;
      if(!permissions) return false;

      if(!permissions[resource]) return false;
      if(locationId === null || !Number.isInteger(Number(locationId))) return false;
      locationId = Number(locationId);

      const scopedPermissions = permissions[resource][locationId];
      if(!scopedPermissions) {
        if(options.reloadIfNoLocationAccess === true) {
          // User's permissions must have been updated. Server will send new permissions
          // on reload.
          window.location.reload();
        }

        return false;
      } else if(permission) {
        if(permission === Permission.Delete) {
          if(scopedPermissions.deleteAll) return true;
          if(
            options.deleteOwner !== undefined &&
            (options.deleteOwner === null || Number(options.deleteOwner) !== userId)) {
            return false;
          }
          return scopedPermissions.deleteSelf;
        }

        return scopedPermissions[permission.toLowerCase() as keyof ActionPermission];
      }

      // if no permissionName specified, user of this API just wants to know
      // whether we have access to the resource at all
      return true;
    },

    /**
     * Returns true if the user has the given permission for any resource under this location.
     * If permission not given, it will return true if user has any permissions at all for this
     * location.
     * @param locationId {number}
     * @param permission {Permission}
     */
    anyForLocation: (locationId: number, permission?: Permission) => {
      if(isAdmin) return true;
      if(!permissions) return false;

      return Object.keys(permissions).some((resource) => {
        return !!permissions[resource][locationId];
      });
    },

    /**
     * Returns true if the user has the given permission at any location for this resource.
     * If permission not given, it will return true if user has any permissions at all for this
     * resource.
     * @param resource {string}
     * @param permission {Permission}
     */
    anyForResource: (resource: string, permission?: Permission) => {
      if(isAdmin) return true;
      if(!permissions) return false;

      if(!permissions[resource]) return false;
      if(!permission) return true;

      const locationRoles = permissions[resource];
      return Object.keys(locationRoles).some((k) => locationRoles[k][permission.toLowerCase() as keyof ActionPermission]);
    },
  }
});

export default buildPermissions;
