import { Loading } from '@resys/opux-react';
import gql from 'graphql-tag';
import { FlatPermission } from 'hooks/usePermissions/permissionTypes';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Role } from 'types';
import ValueSelect from '../ValueSelect';
import roleTypeMapper from './roleTypeMapper';

const SET_PERMISSIONS = gql`
  mutation($permissions: [Object], $role: String) {
    setPermissions(permissions: $permissions) @client
    setDebugForcedRole(role: $role) @client
  }
`;

/**
 * Separating the client and server query is necessary right now since `apollo-link-state` is buggy:
 * https://github.com/apollographql/apollo-link-state/issues/262
 * https://github.com/apollographql/apollo-link-state/issues/265
 * https://github.com/apollographql/apollo-link-state/issues/290
 */
const GET_LOCATIONS = gql`
  query {
    locations(first: 500) {
      restId
    }
  }
`

const GET_ORIGINAL_PERMISSIONS = gql`
  query {
    permissions @client {
      locationId
      resource
      createPermission
      updatePermission
      deleteSelfPermission
      deleteAllPermission
    }
    debug @client {
      forcedRole
    }
  }
`;

const roleMessages = defineMessages({
  VIEWER: {
    id: 'roles.viewer',
    defaultMessage: 'Viewer',
  },
  USER: {
    id: 'roles.user',
    defaultMessage: 'User',
  },
  MANAGER: {
    id: 'roles.manager',
    defaultMessage: 'Manager',
  },
});

class PermissionsQuery extends Query<{
  permissions: FlatPermission[] | null;
  debug: {
    forcedRole: Role | null;
  };
}> {};
class LocationsQuery extends Query<{
  locations: Array<{
    restId: number;
  }>;
}> {};
interface Props {};
export default class DebugForceRole extends React.PureComponent<Props> {
  values: Role[] = ['VIEWER', 'USER', 'MANAGER'];

  render() {
    return (
      <Mutation mutation={SET_PERMISSIONS}>
        {(setPermissions) => (
          <LocationsQuery query={GET_LOCATIONS}>
            {({ loading, data: locationsData }) => {
              if(loading || !locationsData) return <Loading/>;
              return (
                <PermissionsQuery query={GET_ORIGINAL_PERMISSIONS}>
                  {({ loading, data }) => {
                    if(loading || !data) return <Loading/>;

                    return (
                      <ValueSelect
                        values={this.values}
                        selected={data.debug.forcedRole || undefined}
                        onChange={(role) => {
                          if(!role) {
                            setPermissions({
                              variables: {
                                permissions: data.permissions,
                                role: null,
                              },
                            });
                            return;
                          }

                          let permissions: FlatPermission[] = [];
                          locationsData.locations.forEach(location => {
                            const mappedPermissions = roleTypeMapper(location.restId, role);
                            permissions = permissions.concat(mappedPermissions);
                          });

                          setPermissions({
                            variables: {
                              permissions,
                              role,
                            },
                          });

                          this.setState({
                            forcedRole: role,
                          });
                        }}
                      >
                        {(role) => <FormattedMessage {...roleMessages[role]}/>}
                      </ValueSelect>
                    )
                  }}
                </PermissionsQuery>
              );
            }}
          </LocationsQuery>
        )}
      </Mutation>
    )
  }
}
