import React from 'react';
import * as storage from 'settings/storage';
import { Language, Locale } from 'types';

const localeToLanguage: {[key: string]: Language} = {
  en: 'ENGLISH',
  fi: 'FINNISH',
  sv: 'SWEDISH',
};

interface Context {
  selectedLocale: Locale,
  selectedLanguage: Language,
  changeLocale: (locale: Locale) => void,
}
export const LocaleContext = React.createContext<Context>({
  selectedLocale: 'en',
  selectedLanguage: 'ENGLISH',
  changeLocale: (locale: Locale) => {},
});

interface State {
  selectedLocale: Locale;
};

interface Props {
  value: Locale;
  children: (props: Context) => React.ReactNode;
};
export class LocaleProvider extends React.PureComponent<Props, State> {
  state: State = {
    selectedLocale: this.props.value,
  }

  changeLocale = (selectedLocale: Locale) => {
    this.setState({ selectedLocale });
    storage.set('locale', selectedLocale);
  }

  render() {
    const { selectedLocale } = this.state;
    const value = {
      selectedLocale,
      selectedLanguage: localeToLanguage[selectedLocale],
      changeLocale: this.changeLocale,
    };

    return (
      <LocaleContext.Provider value={value}>
        <LocaleContext.Consumer>
          { this.props.children }
        </LocaleContext.Consumer>
      </LocaleContext.Provider>
    );
  }
}
