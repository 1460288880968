import EmailNotificationModal from 'components/EmailNotificationModal';
import LogoutModal from 'components/LogoutModal';
import Responsive from 'components/Responsive';
import { ZIndexProperty } from 'csstype';
import * as csx from 'csx';
import gql from 'graphql-tag';
import withPermissions, { InjectedPermissionProps } from 'hocs/withPermissions';
import { LeftSidebar } from 'opux/portal/LeftSidebar';
import {
  RightSidebar as OpuxRightSidebar,
  RightSidebarProps as OpuxRightSidebarProps,
} from 'opux/portal/RightSidebar';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { style } from 'typestyle';
import isAnonymous from 'util/isAnonymous';
import SidebarItem from './RightSidebarItem';
import { SidebarLanguage } from './SidebarLanguage';
import TenantSelect from './TenantSelect';

const onTopClass = style({
  zIndex: csx.important(9000) as ZIndexProperty,
  $nest: {
    '& .op-navigation': {
      display: 'block',
    },
  },
});

const GET_DATA = gql`
  query {
    fullUserName @client
  }
`;

const TOGGLE_DEBUGBAR = gql`
  mutation($visible: Boolean!) {
    toggleDebugBarVisibility @client
  }
`;

interface Data {
  fullUserName: string | null;
};
class RightSidebarQuery extends Query<Data> {};

interface State {
  open: boolean;
  logoutModalOpen: boolean;
  emailNotificationModalOpen: boolean;
};

export enum Item {
  Logout,
  UserInfo,
  TenantSelect,
  LanguageSelect,
  Debug,
};
interface RightSidebarProps extends OpuxRightSidebarProps, InjectedPermissionProps,
RouteComponentProps {
  shown?: Set<Item>,
};
class RightSidebar extends React.Component<RightSidebarProps, State> {
  state: State = {
    open: false,
    logoutModalOpen: false,
    emailNotificationModalOpen: false,
  };

  openLogout = () => {
    this.setState({ logoutModalOpen: true });
  }

  closeLogout = () => {
    this.setState({ logoutModalOpen: false });
  }

  openEmailNotificationModal = () => {
    this.setState({ emailNotificationModalOpen: true });
  }

  closeEmailNotificationModal = () => {
    this.setState({ emailNotificationModalOpen: false });
  }

  openDocumentation = () => {
    this.props.history.push('/documentation');
  }

  isShown(item: Item): boolean {
    const { shown } = this.props;
    if(!shown) return true;

    return shown.has(item);
  }

  setOpen = (open: boolean) => {
    this.setState({ open });
  }

  render() {
    const { open, logoutModalOpen, emailNotificationModalOpen } = this.state;

    return (
      <>
        { logoutModalOpen && <LogoutModal onClose={this.closeLogout}/> }
        { emailNotificationModalOpen && <EmailNotificationModal onClose={this.closeEmailNotificationModal}/> }
        <OpuxRightSidebar
          {...this.props}
          className={isAnonymous() ? onTopClass : undefined}
          onClick={this.setOpen}
        >
          <LeftSidebar.Body signedIn>
            <SidebarItem icon='lock' shown={this.isShown(Item.Logout)} onClick={this.openLogout}>
              <FormattedMessage id='signin.logout'/>
            </SidebarItem>
            <SidebarItem shown={this.isShown(Item.UserInfo)} icon='person'>
              <RightSidebarQuery query={GET_DATA}>
                {({ loading, error, data }) => {
                  let userName;
                  if(data && data.fullUserName) {
                    userName = data.fullUserName;
                  }

                  return userName || '';
                }}
              </RightSidebarQuery>
            </SidebarItem>
            {this.isShown(Item.TenantSelect) && (
              <TenantSelect closed={!open}/>
            )}
            {this.isShown(Item.LanguageSelect) && (
              <LeftSidebar.Body.Item>
                <SidebarLanguage/>
              </LeftSidebar.Body.Item>
            )}
            <Responsive {...Responsive.onlyComputer}>
              {process.env.NODE_ENV === 'development' && (
                <Mutation mutation={TOGGLE_DEBUGBAR}>
                  {(toggleVisibility) => (
                    <SidebarItem
                      icon='research'
                      shown={this.isShown(Item.Debug)}
                      onClick={() => toggleVisibility()}
                    >
                      Debug (DEV ONLY)
                    </SidebarItem>
                  )}
                </Mutation>
              )}
            </Responsive>
          </LeftSidebar.Body>
        </OpuxRightSidebar>
      </>
    );
  }
}

export default withRouter(withPermissions(RightSidebar));
