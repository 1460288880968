import Button from 'components/Button';
import * as csx from 'csx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { style } from 'typestyle';

const editButtonClass = style({
  minWidth: csx.important(csx.rem(12) as string),
});

interface ButtonProps {
  onClick?: () => void;
}

export class Close extends React.PureComponent<ButtonProps> {
  render() {
    const { onClick } = this.props;

    return (
      <Button size='mini' secondary onClick={onClick}>
        <FormattedMessage id='close' defaultMessage='Close'/>
      </Button>
    );
  }
}

export class Edit extends React.PureComponent<ButtonProps> {
  render() {
    const { onClick } = this.props;
    return (
      <Button
        className={editButtonClass}
        primary
        size='mini'
        float='right'
        onClick={onClick}
        icon='edit'
      >
        <FormattedMessage id='edit' defaultMessage='Edit'/>
      </Button>
    );
  }
}

export class Save extends React.PureComponent<ButtonProps & { loading?: boolean }> {
  render() {
    const { onClick, loading } = this.props;

    return (
      <Button
        className={editButtonClass}
        loading={loading}
        primary
        size='mini'
        float='right'
        onClick={onClick}
        icon={loading ? undefined : 'edit'}
      >
        <FormattedMessage id='save' defaultMessage='Save'/>
      </Button>
    );
  }
}
