import Button from 'components/Button';
import React from 'react';
import restFetch from 'util/restFetch';

function expireSession() {
  restFetch('/logout', { method: 'POST', rootPath: '/' });
}

export default () => (
  <Button onClick={expireSession}>
    Expire session silently
  </Button>
);
