import DebugBar from 'components/DebugBar';
import InactivityTracker from 'components/InactivityTracker';
import LeftSidebar from 'components/LeftSidebar';
import Responsive from 'components/Responsive';
import RightSidebar, { Item as RightSidebarItem } from 'components/RightSidebar';
import Topbar from 'components/Topbar';
import * as csx from 'csx';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import { media, style } from 'typestyle';
import SessionExpiredModal from 'views/SessionExpiredModal';
import AppMainContent from './AppMainContent';

const contentClass = style(media({
  minWidth: Responsive.onlyTablet.minWidth,
  maxWidth: Responsive.onlyTablet.maxWidth,
}, {
  marginRight: csx.important('0px'),
}));

const GET_USER = gql`
  {
    userId @client
  }
`;

interface Props {};
class App extends React.Component<Props> {
  anonRenderer = () => {
    const shown = new Set<RightSidebarItem>();
    shown.add(RightSidebarItem.LanguageSelect);
    return <RightSidebar shown={shown}/>
  }

  mainRenderer = () => (
    <>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <Switch>
          <Route path='/signin' render={() => {
            const shown = new Set<RightSidebarItem>();
            shown.add(RightSidebarItem.LanguageSelect);
            return <RightSidebar shown={shown}/>
          }}/>
          <Route component={RightSidebar}/>
        </Switch>
      </Responsive>
      <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
        <Topbar/>
      </Responsive>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <LeftSidebar/>
      </Responsive>
    </>
  );

  render() {
    return (
      <div className='op-light-gray op-application-page'>
        <div className='op-main-container signed-in namebar'>
          <Switch>
            <Route path='/anon' render={this.anonRenderer}/>
            <Route render={this.mainRenderer}/>
          </Switch>
          <div id='op-wrapper'>
            <div id='op-content' className={contentClass}>
              <DebugBar/>
              <div id='op-content-wrapper'>
                <div id='main-content' className='opux-g-container'>
                  <SessionExpiredModal/>
                  <AppMainContent/>
                </div>
              </div>
            </div>
          </div>
          <Query query={GET_USER}>
            {({ data }) => (
              data && data.userId && (
                <InactivityTracker initialWait={60} actionWait={5}/>
              )
            )}
          </Query>
        </div>
      </div>
    );
  }
}

export default App;
