import { Spacer } from '@resys/opux-react';
import React from 'react';
import { style } from 'typestyle';

const filtersClass = style({
  display: 'flex',
  $nest: {
    '& > *': {
      flex: '1 1 auto',
    },
  },
});
const PageFilters: React.FC<{}> = (props) => (
  <>
    <div className={filtersClass}>
      {props.children}
    </div>
    <Spacer bottomMargin/>
  </>
);
export default PageFilters;
