import { Checkbox, SelectionContainer } from '@resys/opux-react';
import classNames from 'classnames';
import * as csx from 'csx';
import produce from 'immer';
import React from 'react';
import { arrayMove } from 'react-sortable-hoc';
import { style } from 'typestyle';
import { SortableCheckbox, SortableCheckboxList } from './SortableCheckboxSelect';

const verticalClass = style({
  $nest: {
    '& > *': {
      width: csx.important('100%'),
      paddingLeft: csx.important(0),
      paddingRight: csx.important(0),
    },
  },
});

type Value = string | number;
export interface CheckboxSelectItem {
  value: Value;
  label: React.ReactNode;
  className?: string;
  disabled?: boolean;
};
export interface CheckboxSelectProps<T> {
  items: CheckboxSelectItem[];
  value?: T;
  selection?: boolean;
  multiple?: boolean;
  vertical?: boolean;
  sortable?: boolean;
  className?: string;
  onChange?: (value: T) => void;
};

// tslint:disable-next-line
export class CheckboxSelect<T extends Value | Value[]> extends React.PureComponent<CheckboxSelectProps<T>> {
  onChange(item: CheckboxSelectItem) {
    return (event: React.MouseEvent<HTMLDivElement>) => {
      const { value, multiple, onChange } = this.props;
      if(!onChange) return;

      if(!value) {
        if(multiple) {
          onChange([item.value] as T);
        } else {
          onChange(item.value as T);
        }

        return;
      }

      if(Array.isArray(value)) {
        onChange(produce(value, value => {
          const index = value.indexOf(item.value);

          if(index > -1) {
            value.splice(index, 1);
          } else {
            value.push(item.value);
          }
        }));
      } else {
        onChange(item.value as T);
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
    const { onChange, value } = this.props
    if(onChange && value && Array.isArray(value)) {
      onChange(arrayMove(value, oldIndex, newIndex) as T);
    }
  }

  render() {
    const {
      items,
      className,
      value,
      selection,
      vertical,
      sortable,
    } = this.props;

    const ItemComponent = sortable ? SortableCheckbox : Checkbox;
    const choices = items.map((item, i) => {
      let checked;
      if(Array.isArray(value)) {
        checked = value.includes(item.value);
      } else {
        checked = value === item.value;
      }

      const fullClassName = classNames(item.className, {
        'draggable': sortable && checked,
      });
      return (
        <ItemComponent
          key={i}
          index={i}
          disabled={item.disabled}
          checked={checked}
          selection={selection}
          onClick={this.onChange(item)}
          className={fullClassName}
        >
          {item.label}
        </ItemComponent>
      );
    });

    const fullClassName = classNames(className, {
      [verticalClass]: vertical,
    });

    if(sortable) {
      return (
        <SortableCheckboxList
          className={fullClassName}
          helperClass='in-drag'
          onSortEnd={this.onSortEnd}
          distance={10}
          axis='xy'
          selection={selection}
        >
          {choices}
        </SortableCheckboxList>
      )
    }

    if(selection) {
      return (
        <SelectionContainer className={fullClassName}>
          {choices}
        </SelectionContainer>
      )
    }

    return (
      <div className={fullClassName}>{choices}</div>
    );
  }
}
