import Modal from 'components/Modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InactivityTracker as Tracker, setup } from 'util/inactivityTracker';
import restFetch from 'util/restFetch';
import RedirectToSignin from 'views/RedirectToSignin';

interface State {
  isInactive: boolean;
  redirect: boolean;
  actionWaitTimeLeft: number;
}
interface Props {
  // All times are in minutes
  initialWait: number;
  actionWait: number;
}
export default class InactivityTracker extends React.Component<Props, State> {
  actionWaitTimer: NodeJS.Timer | undefined;
  tracker: Tracker | undefined;
  state: State = {
    redirect: false,
    isInactive: false,
    actionWaitTimeLeft: this.props.actionWait,
  }

  runTracker() {
    this.tracker = setup(this.props.initialWait * 60, () => {
      this.actionWaitTimer = setInterval(this.updateActionWaitTimeLeft, 60000);
      this.setState({ isInactive: true, actionWaitTimeLeft: this.props.actionWait });
    });
  }

  updateActionWaitTimeLeft = () => {
    if(this.state.actionWaitTimeLeft <= 1) {
      this.logout();
    }

    this.setState({ actionWaitTimeLeft: this.state.actionWaitTimeLeft - 1 });
  };

  cancel = () => {
    if(this.actionWaitTimer) {
      clearInterval(this.actionWaitTimer);
    }

    this.setState({ isInactive: false, actionWaitTimeLeft: this.props.actionWait });
    this.runTracker();
  };

  async logout() {
    try {
      await restFetch('/logout', { method: 'POST', rootPath: '/' });
    } finally {
      this.setState({ redirect: true });
      this.clearTimers();
    }
  };

  componentDidMount() {
    this.runTracker();
  }

  clearTimers() {
    if(this.tracker) {
      this.tracker.cancel();
    }

    if(this.actionWaitTimer) {
      clearInterval(this.actionWaitTimer);
    }
  }

  componentWillUnmount() {
    this.clearTimers();
  }

  render() {
    const { isInactive, redirect, actionWaitTimeLeft } = this.state;

    if(redirect) {
      return <RedirectToSignin/>
    }

    if(!isInactive) return null;

    return (
      <Modal open>
        <Modal.Content>
          <FormattedMessage
            id='logout.auto'
            defaultMessage={`You have been inactive for a while. You will be automatically logged
            out in {minutes} minute(s). Click close if you wish to stay logged in.`}
            values={{
              minutes: actionWaitTimeLeft,
            }}
          />
        </Modal.Content>
        <Modal.Footer>
          <Modal.CommonButtons.Close onClick={this.cancel}/>
        </Modal.Footer>
      </Modal>
    )
  }
}
