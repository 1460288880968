import { TextAlignProperty } from 'csstype';
import * as csx from 'csx';
import { style } from 'typestyle';

export const opuxRed = csx.color('#d00000');
export const opuxOrange = csx.color('#ff6a10');
export const opuxBlue = csx.color('#069af9');
export const opuxGreen = csx.color('#00ac32');
export const opuxCyan = csx.rgb(88, 185, 195);
export const opuxGrey = csx.color('#6e6e6e');

export const opuxPositive = opuxGreen;
export const opuxNegative = opuxRed;

function color(color: csx.ColorHelper) {
	return style({
		color: csx.important(color.toString()),
	})
}

export type Colors = {
	red: string;
	orange: string;
	blue: string;
	green: string;
	cyan: string;
	grey: string;
};

export const colors: Colors = {
	red: color(opuxRed),
	orange: color(opuxOrange),
	blue: color(opuxBlue),
	green: color(opuxGreen),
	cyan: color(opuxCyan),
	grey: color(opuxGrey),
};

function backgroundColor(color: csx.ColorHelper) {
	return style({
		backgroundColor: csx.important(color.toString()),
	});
}

export const backgroundColors: Colors = {
	red: backgroundColor(opuxRed),
	orange: backgroundColor(opuxOrange),
	blue: backgroundColor(opuxBlue),
	green: backgroundColor(opuxGreen),
	cyan: backgroundColor(opuxCyan),
	grey: backgroundColor(opuxGrey),
};

function createTextAlign(align: TextAlignProperty): string {
	return style({
		textAlign: align,
	});
}

export const textAlignClass = {
	left: createTextAlign('left'),
	center: createTextAlign('center'),
	right: createTextAlign('right'),
};
