import { requestAsHeader, requestAsTenantAdmin } from 'const';
import { getConfig } from 'globalConfig';
import { addHeaderToOptions, appendAnonContext } from './fetchPatch';

export default async function restFetchPatch(info: RequestInfo, options: RequestInit) {
  const config = await getConfig();
  const uri = appendAnonContext(info.toString());

  const addHeader = addHeaderToOptions(options);

  if (window.location.pathname.startsWith('/app/admin/')) {
    addHeader(requestAsHeader, requestAsTenantAdmin);
  }

  if (config && config.csrf) {
    addHeader(config.csrf.headerName, config.csrf.token);
  }

  return fetch(uri, options);
};
