import React from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import { style } from 'typestyle';
import EnableDisable from './EnableDisable';
import { NotificationType, NotificationSettings, calcNextState, calcInitState } from './noti-types';
import restFetch from 'util/restFetch';

const settingsRowClass = style({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});

const labelClass = style({
  flex: 1,
  // marginRight: '10px',
});

const enableDisableClass = style({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

const settingTypes: Record<NotificationType, string> = {
  'TASK_CREATE': 'email.settings.taskCreated',
  'TASK_UPDATE': 'email.settings.taskUpdated',
  'TASK_INCOMPLETE': 'email.settings.taskIncomplete',
  'OBSERVATION_CREATE': 'email.settings.observationCreated',
  'OBSERVATION_UPDATE': 'email.settings.observationUpdated',
  'ASSESSMENT_CREATE': 'email.settings.assessmentCreated',
  'ASSESSMENT_UPDATE': 'email.settings.assessmentUpdated',
  'MONITORING_CREATE': 'email.settings.monitoringCreated',
  'MONITORING_UPDATE': 'email.settings.monitoringUpdated',
};

const EmailNotificationModal: React.FC<{
  onClose: () => void;
  
}> = (props) => {
  const [state, setState] = React.useState<NotificationSettings>({values:[]});

  React.useEffect(()=>{
    restFetch('/currentuser-notifications', {})
    .then((data)=> setState(calcInitState({values: data})))
  }, [])

  function toggleNotificationType(typeToToggle: NotificationType) {
    setState(previousState => calcNextState(typeToToggle, previousState))
  }

  function handleSave() {
    restFetch('/currentuser-notifications', {data: state.values, method: 'POST'})
    .then((data)=> {
      props.onClose();
    })
  }



  return (
    <Modal open>
      <Modal.Content>
        <Modal.Header>
          <FormattedMessage
            id="email.notification.settings"
            defaultMessage="Email notification settings"
          />
        </Modal.Header>

        {state.values.map(({ notificationType, rule }) => (
          <div className={settingsRowClass} key={notificationType}>
            <div className={labelClass}>
              <FormattedMessage id={settingTypes[notificationType]} defaultMessage={notificationType} />
            </div>
            <div className={enableDisableClass}>
              <EnableDisable value={rule} onSelect={() => toggleNotificationType(notificationType)} />
            </div>
          </div>
        ))}

      </Modal.Content>
      <Modal.Footer>
        <Button secondary onClick={props.onClose}>
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
        <Button primary disabled={false} onClick={handleSave}>
          <FormattedMessage id="save" defaultMessage="Save" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmailNotificationModal;