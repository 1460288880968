import { Checkbox, CheckboxProps, SelectionContainer } from '@resys/opux-react';
import classNames from 'classnames';
import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

export const SortableCheckbox = SortableElement<CheckboxProps>((props) => (
  <Checkbox {...props}/>
));

interface ListProps {
  children: React.ReactNode,
  className: string;
  selection?: boolean;
};
export const SortableCheckboxList = SortableContainer<ListProps>(({
  children,
  className,
  selection,
}) => {
  const Component = selection ? SelectionContainer : 'div';
  return (
    <Component className={classNames(className, 'no-select')}>{children}</Component>
  );
});
