import React from 'react';

const SiteName = ({ children }: { children?: React.ReactNode }) => (
  <span className='site-name'>{children}</span>
);

const SiteEnvironment = ({ children }: { children?: React.ReactNode }) => (
  <span className='site-environment'>{children}</span>
)

interface LeftSidebarLogoProps {
  title?: string;
};
export class LeftSidebarLogo extends React.PureComponent<LeftSidebarLogoProps> {
  static Name = SiteName;
  static Environment = SiteEnvironment;

  render() {
    const { title } = this.props;

    return (
      <div className='op-sidebar-top-wrapper'>
        <div className='op-logo-container' title={title} aria-label={title}>
          <div className='op-logo with-text'>
            &nbsp;
            <div className='site-name-container'>
              { this.props.children }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
