import React from 'react';
import { Link } from 'react-router-dom';

const Item = (props: { children?: React.ReactNode, onClick?: () => void, name?: string }) => (
  <li className='op-topbar-tab-list' {...props}>
    <a className='op-topbar-tab' data-topbar-tab={props.name} onClick={props.onClick}>
      { props.children }
    </a>
  </li>
);

interface TopbarProps {};
export class Topbar extends React.PureComponent<TopbarProps> {
  static Item = Item;

  render() {
    return (
      <aside
        id='op-topbar'
        role='menubar'
        tabIndex={0}
        aria-label='Top bar'
      >
        <ul id='op-topbar-tabs'>
          <li className='op-topbar-tab-logo'>
            <Link to='/' className='op-logo-container' title='To the home page'>
              <div className='op-logo'/>
            </Link>
          </li>
          <li className='op-topbar-tab-expander'>
            <div className='wrapper'/>
          </li>
          { this.props.children }
        </ul>
      </aside>
    );
  }
}
