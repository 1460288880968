import { FlatPermission } from 'hooks/usePermissions/permissionTypes';
import { Permission, RestrictedResource, Role } from 'types';

/**
 * Maps role to permissions
 * TODO: maybe instead of this static mapper, server should send role => permission configuration
 * info to client. Then we could do this mapping off of the configuration that server sends, in
 * result making this future-proof in the sense that the mapping is always in sync between the
 * client and server, even if the underlying mapping changes.
 * If we implement this, then we can also just send role types to client instead of full permissions
 * data and map it around on client-side, saving some bytes on network traffic.
 *
 * @param locationId Id of the location where user has the role
 * @param role Role type
 */
export default function roleToPermissions(locationId: number, role: Role): FlatPermission[] {
  const permissions: { [resource: string]: Set<Permission> } = {};
  function addPermission(resource: RestrictedResource, givenPermissions: Permission[]) {
    if(!permissions[resource]) permissions[resource] = new Set();
    for(const permission of givenPermissions) {
      permissions[resource].add(permission);
    }
  }

  // These roles are given to all users who have access to a location
  addPermission(RestrictedResource.Observation, [Permission.Create]);
  addPermission(RestrictedResource.ObservationComment, [Permission.Create]);
  addPermission(RestrictedResource.Task, []);
  addPermission(RestrictedResource.TaskComment, [Permission.Create]);
  addPermission(RestrictedResource.Form, []);

  if(role === 'USER') {
    addPermission(RestrictedResource.Observation, [
      Permission.Create,
      Permission.Update,
      Permission.DeleteSelf,
    ]);
    addPermission(RestrictedResource.Task, [
      Permission.Create,
      Permission.Update,
      Permission.DeleteSelf,
    ]);
    addPermission(RestrictedResource.Assessment, [
      Permission.Create,
      Permission.Update,
    ]);
    addPermission(RestrictedResource.Monitoring, [
      Permission.Create,
      Permission.Update,
    ]);
  } else if(role === 'MANAGER') {
    addPermission(RestrictedResource.Observation, [
      Permission.Create,
      Permission.Update,
      Permission.DeleteAll,
    ]);
    addPermission(RestrictedResource.Task, [
      Permission.Create,
      Permission.Update,
      Permission.DeleteAll,
    ]);
    addPermission(RestrictedResource.Assessment, [
      Permission.Create,
      Permission.Update,
      Permission.DeleteAll,
    ]);
    addPermission(RestrictedResource.Monitoring, [
      Permission.Create,
      Permission.Update,
      Permission.DeleteAll,
    ]);
  }

  return Object.entries(permissions).map(([resource, permissionSet]) => {
    return {
      locationId,
      resource,
      createPermission: permissionSet.has(Permission.Create),
      updatePermission: permissionSet.has(Permission.Update),
      deleteSelfPermission: permissionSet.has(Permission.DeleteSelf),
      deleteAllPermission: permissionSet.has(Permission.DeleteAll),
    }
  });
}
