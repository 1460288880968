import React from 'react';

interface ModalContentState {
  height: number;
  width: number;
  footerDiv?: HTMLDivElement;
};
interface ModalContentProps {};
class ModalContent extends React.PureComponent<ModalContentProps, ModalContentState> {
  state: ModalContentState = {
    height: window.innerHeight,
    width: window.innerWidth,
  };

  handleResize = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  handleRef = (ref: HTMLDivElement) => {
    if(ref && ref.nextElementSibling) {
      this.setState({
        footerDiv: ref.nextElementSibling as HTMLDivElement,
      });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { height, width, footerDiv } = this.state;
    const footerHeight = footerDiv ? footerDiv.clientHeight : undefined;

    let maxHeight;
    let minHeight;
    if(width >= 768) {
      maxHeight = height - 200;
    } else if(width <= 588 || height <= 600) {
      maxHeight = height - (footerHeight || 115);
      minHeight = maxHeight;
    } else {
      maxHeight = height - 200;
    }

    const style = {
      maxHeight,
      minHeight,
    };

    return (
      <div ref={this.handleRef} className='opux-modal-body' style={style}>
        <div className='opux-g-container'>
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default ModalContent;
