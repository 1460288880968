import Button from 'components/Button';
import Page from 'components/Page';
import gql from 'graphql-tag';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';

const GET_USER_INFO = gql`
  {
    userRestId @client
  }
`;

const ACCEPT_TERMS = gql`
  mutation acceptTerms($time: String!) {
    acceptTerms(
      id: $id,
      input: {termsAccepted: $time}
    ) @rest(
      path: "/users/:id", method: "PUT", type: "user"
    ) {
      id
    }
  }
`;

interface Props {}
interface State {
  termsHTML?: string;
}
class TermsAndConditionsPage extends React.PureComponent<Props, State> {
  state: State = {};

  async componentWillMount() {
    const response = await fetch('/terms_fi.html');
    const text = await response.text();

    this.setState({
      termsHTML: text,
    });
  }

  render() {
    return (
      <Page>
        <Page.Heading>
          <FormattedMessage id='terms_and_conditions' defaultMessage='Terms and conditions'/>
        </Page.Heading>
        {this.state.termsHTML && (
          <>
            <div dangerouslySetInnerHTML={{ __html: this.state.termsHTML }}/>
            <Query query={GET_USER_INFO}>
              {({ data }) => (
                <Mutation mutation={ACCEPT_TERMS} onCompleted={() => window.location.reload()}>
                  {(mutate, { loading }) => (
                    <Button
                      type='button'
                      loading={loading}
                      onClick={() => mutate({
                        variables: {
                          id: data.userRestId,
                          time: new Date().toISOString(),
                        },
                      })}
                      primary
                    >
                      <FormattedMessage
                        id='terms_and_conditions.accept'
                        defaultMessage='Accept terms and conditions'
                      />
                    </Button>
                  )}
                </Mutation>
              )}
            </Query>
          </>
        )}
      </Page>
    )
  }
}

export default TermsAndConditionsPage;
