import { Icon as OpuxIcon, IconProps } from '@resys/opux-react';
import classNames from 'classnames';
import React from 'react';

const Icon: React.FC<IconProps> = ({ className, onClick, ...restProps }) => {
  const fullClassName = classNames(className, {
    'clickable': onClick,
  });

  return <OpuxIcon {...restProps} className={fullClassName} onClick={onClick}/>
}

export default Icon;
