import Modal from 'components/Modal';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import RedirectToSignin from 'views/RedirectToSignin';

const GET_SESSION_INFO = gql`
  {
    session @client {
      expired
    }
  }
`;

interface Props {};
interface State {
  redirect: boolean;
};
interface Data {
  session: {
    expired: boolean;
  };
}
class SessionQuery extends Query<Data> {};
export default class SessionExpiredModal extends React.PureComponent<Props, State> {
  state: State = {
    redirect: false,
  };

  onClose = () => {
    this.setState({
      redirect: true,
    });
  }

  render() {
    if(this.state.redirect) {
      return <RedirectToSignin/>
    }

    return (
      <SessionQuery query={GET_SESSION_INFO}>
        {({ data }) => {
          if(!data || !data.session || !data.session.expired) {
            return null;
          }

          return (
            <Modal open>
              <Modal.Content>
                <Modal.Header>
                  <FormattedMessage id='session.expired.title' defaultMessage='Session expired'/>
                </Modal.Header>
                <FormattedMessage
                  id='session.expired.content'
                  defaultMessage='You need to reload page'
                />
              </Modal.Content>
              <Modal.Footer>
                <Modal.CommonButtons.Close onClick={this.onClose}/>
              </Modal.Footer>
            </Modal>
          );
        }}
      </SessionQuery>
    );
  }
}
