import { OpuxIconName } from '@resys/opux-react';
import Icon from 'components/Icon';
import { Location } from 'history';
import withPermissions, { InjectedPermissionProps } from 'hocs/withPermissions';
import { LeftSidebar as OpuxLeftSidebar } from 'opux/portal/LeftSidebar';
import React from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import {
  Link as RouterLink,
  match,
  NavLink as RouterNavLink,
  Route,
  Switch
} from 'react-router-dom';
import 'styles/left-sidebar.css';

class LogoInfo extends React.PureComponent<{}> {
  render() {
    const buildVersion = process.env.REACT_APP_VERSION;
    return (
      <RouterLink to='/'>
        <OpuxLeftSidebar.Logo>
          <OpuxLeftSidebar.Logo.Name/>
          <OpuxLeftSidebar.Logo.Environment>
            {buildVersion}
          </OpuxLeftSidebar.Logo.Environment>
        </OpuxLeftSidebar.Logo>
      </RouterLink>
    );
  }
}

interface NavLinkProps {
  to: string;
  icon?: OpuxIconName;
}
const NavLink: React.FC<NavLinkProps> = ({ icon, to, children }) => (
  <OpuxLeftSidebar.Body.Item as={(linkProps) => (
    <RouterNavLink to={to} {...linkProps}>
      {children}
      {icon && (
        <Icon name={icon}/>
      )}
    </RouterNavLink>
  )}/>
);

const ParentLink = (props: {
  to: string,
  title: React.ReactNode,
  icon: string;
  match?: (match: match<any>, location: Location<any>) => boolean,
  onHover?: () => void,
  children?: React.ReactNode,
}) => (
  <OpuxLeftSidebar.Body.Item as={(linkProps) => (
    <RouterNavLink to={props.to} {...linkProps} isActive={props.match} onMouseEnter={props.onHover}>
      <Icon name={props.icon} circled/>
      <span className='op-navi-text'>
        {props.title}
      </span>
    </RouterNavLink>
  )}>
    <ul
      className='op-navi-level op-navi-level-2 op-navi-level-open op-navi-list'
      role='tree'
      onMouseEnter={props.onHover}
    >
      <Route path={props.to} render={p => {
        if(props.match && !props.match(p.match, p.location)) return null;
        return props.children;
      }}/>
    </ul>
  </OpuxLeftSidebar.Body.Item>
);

interface Props extends InjectedPermissionProps {
  topbarOpen?: boolean;
  followScroll?: boolean;
  onClick?: () => void;
};
class LeftSidebar extends React.Component<Props> {
  render() {
    const { permission, topbarOpen, followScroll, onClick } = this.props;

    return ReactDOM.createPortal(
      <OpuxLeftSidebar followScroll={followScroll} topbarOpen={topbarOpen} onClick={onClick}>
        <Switch>
          <Route path='/signin' render={() => (
            <RouterLink to='/signin'>
              <OpuxLeftSidebar.Logo title='To the main page'>
                <OpuxLeftSidebar.Logo.Name/>
              </OpuxLeftSidebar.Logo>
            </RouterLink>
          )}/>
          <Route component={LogoInfo}/>
        </Switch>
        <OpuxLeftSidebar.Body signedIn>
          <Switch>
            <Route path='/anon'/>
            <Route path='/signin'/>
            <Route render={({ location }) => (
              <>
                <ParentLink
                  icon='insurance'
                  to='/'
                  match={(_m, l) => !l.pathname.includes('admin')}
                  title={<FormattedMessage id='riskmanagement.title' defaultMessage='Home'/>}
                  onHover={() => import('views/App/MainRoutes')}
                >
                  <NavLink to='/observations'>
                    <FormattedMessage id='observations.title' defaultMessage='Observations'/>
                  </NavLink>
                  <NavLink to='/tasks'>
                    <FormattedMessage id='tasks.title' defaultMessage='Tasks'/>
                  </NavLink>
                  {permission.anyForResource('assessment') && (
                    <NavLink to='/assessments'>
                      <FormattedMessage id='assessments.title' defaultMessage='Assessments'/>
                    </NavLink>
                  )}
                  {permission.anyForResource('monitoring') && (
                    <NavLink to='/monitorings'>
                      <FormattedMessage id='monitorings.title' defaultMessage='Monitorings'/>
                    </NavLink>
                  )}
                  <NavLink to='/locations'>
                    <FormattedMessage id='locations.title' defaultMessage='Locations'/>
                  </NavLink>
                  <NavLink to='/assessmentForms'>
                    <FormattedMessage id='assessmentforms.title' defaultMessage='Forms'/>
                  </NavLink>
                  {permission.isSystemAdmin && (
                    <NavLink to='/questionGroups'>
                      <FormattedMessage id='questiongroups.title' defaultMessage='Question groups'/>
                    </NavLink>
                  )}
                </ParentLink>
                {permission.isTenantAdmin && (
                  <ParentLink
                    to='/admin'
                    title={
                      <FormattedMessage
                        id='administration.tenant'
                        defaultMessage='Administration'
                      />
                    }
                    icon='organization'
                    onHover={() => import('views/App/AdminRoutes')}
                  >
                    <NavLink to='/admin/users'>
                      <FormattedMessage id='users.title' defaultMessage='Users'/>
                    </NavLink>
                    <NavLink to='/admin/locations'>
                      <FormattedMessage id='locations.title' defaultMessage='Locations'/>
                    </NavLink>
                    <NavLink to='/admin/review'>
                      <FormattedMessage id='review.title' defaultMessage='Risk review'/>
                    </NavLink>
                    <NavLink to={`${location.pathname}/tenantEdit`} icon='external-link'>
                      <FormattedMessage
                        id='sidebar.admin.tenant.edit'
                        defaultMessage='Edit organization details'
                      />
                    </NavLink>
                  </ParentLink>
                )}
                {permission.isSystemAdmin && (
                  <ParentLink
                    to='/sysadmin'
                    title={
                      <FormattedMessage
                        id='administration.system'
                        defaultMessage='System administration'
                      />
                    }
                    icon='admin'
                    onHover={() => import('views/App/SysAdminRoutes')}
                  >
                    <NavLink to='/sysadmin/reports'>
                      <FormattedMessage id='reports' defaultMessage='Reports'/>
                    </NavLink>
                    <NavLink to='/sysadmin/tenants'>
                      <FormattedMessage id='tenants.title' defaultMessage='Customers'/>
                    </NavLink>
                    <NavLink to='/sysadmin/users'>
                      <FormattedMessage id='users.title' defaultMessage='Users'/>
                    </NavLink>
                    <NavLink to='/sysadmin/assessmentforms'>
                      <FormattedMessage
                        id='assessmentforms.global.title'
                        defaultMessage='Global forms'
                      />
                    </NavLink>
                  </ParentLink>
                )}
              </>
            )}/>
          </Switch>
        </OpuxLeftSidebar.Body>
      </OpuxLeftSidebar>
    , document.body);
  }
}

export default withPermissions(LeftSidebar);
