import { Loading } from '@resys/opux-react';
import usePermissions from 'hooks/usePermissions';
import buildPermissions from 'hooks/usePermissions/buildPermissions';
import React from 'react';
import { Omit } from 'types';

export interface InjectedPermissionProps {
  permission: ReturnType<typeof buildPermissions>,
}

function withPermissions<
  Props extends InjectedPermissionProps,
  ComponentProps = Omit<Props, 'permission'>
>(
  WrappedComponent: React.ComponentType<Props>
): React.FC<ComponentProps> {
  return (props: ComponentProps) => {
    const permission = usePermissions();

    if(!permission || permission === 'Error') {
      return <Loading/>;
    }

    return (
      <WrappedComponent
        // TODO: Bug in TS3.2, see https://github.com/Microsoft/TypeScript/issues/28748
        {...props as any}
        permission={permission}
      />
    );
  }
};

export default withPermissions;
