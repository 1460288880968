import * as csx from 'csx';
import { style } from 'typestyle';

export const containerClass = style({
  display: 'flex',
  height: 48,
  width: csx.percent(100),
  padding: '0px 30px',
  backgroundColor: '#fff',
  borderBottom: csx.border({
    color: '#d7d7d7',
    style: 'solid',
    width: 1,
  }),
  $nest: {
    '&>*': {
      display: 'flex',
      alignItems: 'center',
      padding: 5,
    },
    '& > * + *': {
      borderLeft: csx.border({
        color: '#c2c2c2',
        style: 'solid',
        width: 1,
      }),
    },
  },
});
