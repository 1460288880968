import { LocaleContext } from 'context/locale-provider';
import gql from 'graphql-tag';
import React from 'react';
import { Mutation, Query } from 'react-apollo';
import { Locale } from 'types';
import isAnonymous from 'util/isAnonymous';

const GET_USER_ID = gql`
  {
    userRestId @client
  }
`;

const UPDATE_USER_LANGUAGE = gql`
  mutation updateUserLanguage($userId: Int!, $language: String!) {
    updateUserLanguage(
      userId: $userId,
      input: {language: $language}
    ) @rest(
      path: "/users/:userId", method: "PUT", type: "user"
    ) {
      id
    }
  }
`;

const LocaleItem = ({ name, onClick }: { name: string, onClick: () => void }) => (
  <a
    className='taglib-language-list-text'
    onClick={onClick}
  >
    { name.toUpperCase() }
  </a>
);

const locales = {
  fi: 'FINNISH',
  sv: 'SWEDISH',
  en: 'ENGLISH',
};
interface Props {};
export class SidebarLanguage extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className='op-sidebar-module language'
        data-topbar-tab-content='language'
      >
        <LocaleContext.Consumer>
          {({ changeLocale, selectedLocale }) => (
            <Query query={GET_USER_ID}>
              {({ data }) => (
                <Mutation mutation={UPDATE_USER_LANGUAGE}>
                  {(updateUserLanguage) => (
                    (Object.keys(locales) as Locale[]).map((l: Locale) => {
                      if(l === selectedLocale) {
                        return (
                          <span key={l} className='taglib-language-list-text last'>
                            { l.toUpperCase() }
                          </span>
                        );
                      }

                      return (
                        <LocaleItem
                          key={l}
                          name={l}
                          onClick={() => {
                            changeLocale(l);
                            if(data && data.userRestId && !isAnonymous()) {
                              updateUserLanguage({variables: {
                                userId: data.userRestId,
                                language: locales[l],
                              }});
                            }
                          }}
                        />
                      );
                    })
                  )}
                </Mutation>
              )}
            </Query>
          )}
        </LocaleContext.Consumer>
      </div>
    );
  }
}
