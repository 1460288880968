import { Loading } from '@resys/opux-react';
import Icon from 'components/Icon';
import { restUrl } from 'const';
import gql from 'graphql-tag';
import { LeftSidebar } from 'opux/portal/LeftSidebar';
import React from 'react';
import { Mutation, Query } from 'react-apollo';

const GET_LOGO_DATA = gql`
  query {
    activeTenant @client {
      name
      id
    }
    userTenants @client {
      name
      id
    }
    userRestId @client
  }
`;

interface LogoData {
  activeTenant: null | {
    name: string;
    id: number;
  };
  userTenants: null | Array<{
    name: string;
    id: number;
  }>;
  userRestId: null | number;
}

const UPDATE_DEFAULT_TENANT = gql`
  mutation updateDefaultTenant($userId: Int!, $tenantId: Int!, $tenantHref: String!) {
    updateDefaultTenant(
      userId: $userId,
      input: {defaultTenant: $tenantHref}
    ) @rest(
      path: "/users/:userId", method: "PUT", type: "user"
    ) {
      id
    }
  }
`;

class LogoDataQuery extends Query<LogoData> {};
interface Props {
  closed: boolean;
};
interface State {
  open: boolean;
};
export default class TenantSelect extends React.PureComponent<Props, State> {
  state: State = {
    open: false,
  };

  toggle = (e: React.MouseEvent) => {
    let open = !this.state.open;
    if(this.props.closed) {
      open = true;
    } else {
      e.stopPropagation();
    }
    this.setState({ open });
  }

  render() {
    const { closed } = this.props;
    const { open } = this.state;

    return (
      <LogoDataQuery query={GET_LOGO_DATA}>
        {({ loading, data, error }) => {
          if(error || !data || !data.activeTenant || !data.userTenants || !data.userRestId) {
            return <Loading/>
          }

          const { activeTenant, userTenants, userRestId } = data;
          return (
            <LeftSidebar.Body.Item as={(props) => (
              <a onClick={this.toggle} {...props}>
                <Icon name='organization'/>
                <span className='op-navi-text'>
                  {activeTenant.name}
                </span>
              </a>
            )}>
              <ul
                className='op-navi-level op-navi-level-2 op-navi-level-open op-navi-list'
                role='tree'
              >
                {userTenants.map(t => {
                  if(t.id === activeTenant.id) return null;

                  return (
                    <Mutation onCompleted={() => {
                      // Note: this is done to just refresh all queries. The behaviour could be
                      // improved upon and instead of refresh we could force Apollo to re-pull
                      // all queries somehow.
                      // If you do that, add `setActiveTenant(tenantId: $tenantId)` to mutation
                      // call above.

                      // NB! Note that Mutation must stay rendered until query finishes and
                      // `onComplete` gets called. If we remove it from the tree before that, we
                      // won't get a refresh.
                      window.location.reload();
                    }} mutation={UPDATE_DEFAULT_TENANT} key={t.id}>
                      {(mutate) => !closed && open && (
                        <LeftSidebar.Body.Item as={(props) => (
                          <a {...props} onClick={() => {
                            mutate({
                              variables: {
                                userId: userRestId,
                                tenantId: t.id,
                                tenantHref: `${restUrl}/tenants/${t.id}`,
                              },
                            });
                          }}>
                            <span className='op-navi-text'>
                              {t.name}
                            </span>
                          </a>
                        )}/>
                      )}
                    </Mutation>
                  );
                })}
              </ul>
            </LeftSidebar.Body.Item>
          );
        }}
      </LogoDataQuery>
    );
  }
}
