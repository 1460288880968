import classNames from 'classnames';
import * as csx from 'csx';
import { backgroundColors, Colors } from 'opux/styles';
import React from 'react';
import { style } from 'typestyle';

const inactiveClass = style({ opacity: .3 });

const circularClass = style({
  display: 'inline-block',
  width: 25,
  height: 25,
  borderRadius: csx.percent(50),
  color: '#fff',
  fontSize: 16,
  paddingTop: 2,
  fontWeight: 700,
  textAlign: 'center',
});

const labelClass = style({
  borderRadius: 10,
  padding: '2px 10px 2px 10px',
  color: '#fff',
});

const containerClass = style({
  display: 'inline-flex',
  alignItems: 'center',
  $nest: {
    '&>:nth-child(2)': {
      marginLeft: 10,
    },
  },
});

interface Props extends React.HTMLAttributes<HTMLElement> {
  color?: keyof Colors;
  inactive?: boolean;
  withLabel?: boolean;
  circular?: boolean;
};

export default class Indicator extends React.PureComponent<Props> {
  render() {
    const { inactive, withLabel, color, circular, onClick, children, ...restProps } = this.props;

    const visualizerClass = classNames(
      color && backgroundColors[color],
      {
        [circularClass]: circular,
        [labelClass]: !circular && color,
      }
    );

    const fullContainerClass = classNames(
      containerClass,
      {
        [inactiveClass]: inactive,
      }
    );

    if(withLabel) {
      return (
        <div
          className={fullContainerClass}
          onClick={onClick}
          {...restProps}
        >
          <span className={visualizerClass}/>
          { withLabel && <span>{ children }</span>}
        </div>
      );
    }

    return (
      <span className={classNames(visualizerClass, { [circularClass]: circular })} {...restProps}>
        { children }
      </span>
    );
  }
}
