export interface InactivityTracker {
  cancel: () => any;
};

/**
 * Sets up an inactivity tracker. This function will call the callback only after it has detected
 * that the user has been inactive for the specified amount of time.
 * @param length Length of inactivity before callback is called in seconds
 */
export function setup(length: number, callback: () => any): InactivityTracker {
  const fullLength = length * 1000;

  const onClick = () => {
    clearTimeout(timer);
    timer = setTimeout(complete, fullLength);
  };

  const complete = () => {
    document.removeEventListener('click', onClick);
    callback();
  };

  let timer = setTimeout(complete, fullLength);
  document.addEventListener('click', onClick);

  return {
    cancel: () => {
      clearTimeout(timer);
      document.removeEventListener('click', onClick);
    },
  };
}
