import classNames from 'classnames';
import { CheckboxSelect } from 'components/CheckboxSelect';
import * as csx from 'csx';
import { Colors, backgroundColors, colors, opuxOrange } from 'opux/styles';
import React from 'react';
import { style } from 'typestyle';

const activeClass = style({
  color: csx.important('#fff'),
  backgroundImage: csx.important('none'),
});

const activeNoColorClass = style({
  color: csx.important(opuxOrange.toHexString()),
  backgroundImage: csx.important('none'),
});

const fluidClass = style({
  display: 'flex',
  flex: '1 1 auto',
  $nest: {
    '&>label': {
      width: csx.percent(100),
    },
  },
});

const Segment = ({
    name,
    value,
    className,
    disabled,
    onClick,
    type,
    children,
  }: {
    name: string,
    value: string,
    disabled?: boolean,
    className?: string,
    onClick: () => void,
    type: 'left' | 'middle' | 'right',
    children?: React.ReactNode,
  }) => (
  <React.Fragment>
    <input
      disabled={disabled}
      className={`opux-segment-${type}`}
      name={name}
      value={value}
      type='radio'
    />
    <label className={className} onClick={onClick}>
      { children }
    </label>
  </React.Fragment>
);

interface Props<T extends string> {
  onChange: (value: T | null) => any;
  valueColor?: {
    [K in T]: string | undefined;
  };
  values?: T[];
  selected?: T;
  className?: string;
  checkbox?: boolean;
  disabled?: boolean;
  vertical?: boolean;
  fluid?: boolean;
  children: (value: T) => React.ReactNode;
};
export default class ValueSelect<T extends string> extends React.PureComponent<Props<T>> {
  onClick(value: T) {
    return () => {
      if(this.props.selected === value) {
        this.props.onChange(null);
      } else {
        this.props.onChange(value);
      }
    }
  }

  getType(values: any[], i: number) {
    if(i === 0) {
      return 'left';
    } else if(i === values.length - 1) {
      return 'right';
    }

    return 'middle';
  }

  render() {
    const {
      valueColor,
      values,
      onChange,
      selected,
      className,
      checkbox: list,
      fluid,
      vertical,
      disabled,
      children: renderer,
    } = this.props;

    if(!values && !valueColor) {
      return null;
    }

    if(list) {
      const opts = values || Object.keys(valueColor as any);
      const items = opts.map((value: any) => ({
        value,
        label: renderer(value),
      }));
      return (
        <CheckboxSelect
          selection
          vertical={vertical}
          items={items}
          onChange={onChange as any}
          value={selected}
        />
      );
    }

    let segments;
    if(values) {
      segments = values.map((value, i) => (
        <Segment
          key={value}
          name={values.join(':')}
          value={value}
          type={this.getType(values, i)}
          disabled={disabled}
          onClick={this.onClick(value)}
          className={classNames({
            [activeNoColorClass]: value === selected,
          })}
        >
          {renderer(value)}
        </Segment>
      ));
    } else if(valueColor) {
      const entries: Array<[T, keyof Colors]> = (Object.entries(valueColor) as any);
      segments = entries.map(([value, color], i) => (
        <Segment
          key={value}
          name={Object.keys(valueColor).join(':')}
          value={value}
          type={this.getType(entries, i)}
          disabled={disabled}
          onClick={this.onClick(value)}
          className={classNames(colors[color], {
            [backgroundColors[color]]: value === selected,
            [activeClass]: value === selected,
          })}
        >
          {renderer(value)}
        </Segment>
      ));
    }

    const fullClassName = classNames(
      'opux-input-segmented',
      className,
      {
        [fluidClass]: fluid,
      }
    )
    return (
      <div className={fullClassName}>
        { segments }
      </div>
    );
  }
}
