import classNames from 'classnames';
import React from 'react';
import 'styles/right-sidebar.css';

const RightSidebarItem = ({ children }: { children: React.ReactNode }) => (
  <div className='portlet-borderless-container'>
    <div className='portlet-body'>
      {children}
    </div>
  </div>
);

export interface RightSidebarProps {
  topbarOpen?: boolean;
  onClick?: (open: boolean) => void;
  className?: string;
};
interface RightSidebarState {
  open: boolean;
}
export class RightSidebar extends React.PureComponent<RightSidebarProps, RightSidebarState> {
  static Item = RightSidebarItem;

  state: RightSidebarState = {
    open: false,
  };

  handleClick = () => {
    const { onClick } = this.props;
    this.setState({ open: !this.state.open });

    if(onClick) {
      onClick(!this.state.open);
    }
  }

  render() {
    const { topbarOpen, className } = this.props;
    const { open } = this.state;

    const fullClassName = classNames(
      className,
      'op-sidebar',
      'dockbar-ready',
      {
        'op-sidebar-open': open,
        'op-topbar-sidebar-open': topbarOpen,
        'op-topbar-sidebar-closed': !topbarOpen,
      }
    );

    return (
      <aside
        id='op-right-sidebar'
        className={fullClassName}
        aria-expanded={open}
        onClick={this.handleClick}
      >
        <div className='op-sidebar-wrapper scroll-follow'>
          <div className='op-sidebar-content'>
            {this.props.children}
          </div>
        </div>
        <div className='op-sidebar-toggle'>
          <a role='button' aria-controls='op-right-sidebar' aria-label='Expand support bar'>
            <span className='op-sidebar-collapsed-cover' />
          </a>
        </div>
      </aside>
    );
  }
}
