import React from 'react';
import { Route, RouteProps } from 'react-router';

class PageRoute extends React.PureComponent<RouteProps> {

  render() {
    return <Route {...this.props} />
  }

}

export default PageRoute;
