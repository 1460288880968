import React from 'react';

interface Props {};
export default class DebugBarItem extends React.PureComponent<Props> {
  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}
