import React from 'react';
import { style } from 'typestyle';

const className = style({
  fontSize: 16,
  lineHeight: 0.5,
});

interface Props {};
const ModalSubHeader: React.FC<Props> = ({ children }) => (
  <div className={className}>{children}</div>
);

export default ModalSubHeader;
