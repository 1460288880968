import Button from 'components/Button';
import Modal from 'components/Modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import restFetch from 'util/restFetch';
import RedirectToSignin from 'views/RedirectToSignin';

interface Props {
  onClose: () => void;
};
interface State {
  redirect: boolean;
}
export default class LogoutModal extends React.PureComponent<Props, State> {
  state: State = {
    redirect: false,
  };

  logout = async () => {
    try {
      await restFetch('/logout', { method: 'POST', rootPath: '/' });
    } finally {
      this.setState({ redirect: true });
    }
  }

  render() {
    if(this.state.redirect) {
      return <RedirectToSignin/>
    }

    return (
      <Modal open>
        <Modal.Content>
          <h2><FormattedMessage id='signin.logout.header' defaultMessage='Confirm log out'/></h2>
        </Modal.Content>
        <Modal.Footer>
          <Button
            onClick={this.props.onClose}
            size='mini'
            float='left'
            type='button'
            secondary
          >
            <FormattedMessage id='signin.cancel' defaultMessage='Cancel'/>
          </Button>
          <Button
            onClick={this.logout}
            size='mini'
            float='right'
            type='submit'
            primary
          >
            <FormattedMessage id='signin.logout' defaultMessage='Log out'/>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
