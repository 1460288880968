const isEqual = (newArgs: unknown[], lastArgs: unknown[]): boolean => {
  if(newArgs.length !== lastArgs.length) return false;
  return newArgs.every((newArg, index) => newArg === lastArgs[index]);
};

/**
 * Memoizes a function's last result: if the function is called with the same arguments multiple
 * times in a row, its result is cached and returned from cache on subsequent calls. If the function
 * is called again with different arguments, the function is ran again and its result newly cached.
 * @param fn Function to memoize
 */
export default function memoize<T extends (...args: any[]) => any>(this: any, fn: T): T {
  let lastThis: any;
  let lastArgs: any[] = [];
  let lastResult: any;
  let calledOnce: boolean = false;

  const result = (...newArgs: any[]) => {
    if(calledOnce && lastThis === this && isEqual(newArgs, lastArgs)) {
      return lastResult;
    }

    lastResult = fn.apply(this, newArgs);
    calledOnce = true;
    lastThis = this;
    lastArgs = newArgs;
    return lastResult;
  }

  return result as T;
}
