import { requestAsHeader, requestAsTenantAdmin } from 'const';
import { getConfig } from 'globalConfig';
import qs from 'query-string';

export function appendAnonContext(uri: string) {
  if (window.location.pathname.startsWith('/app/anon')) {
    const locationParams = qs.parse(window.location.search);
    const { url: path, query } = qs.parseUrl(uri);
    query.a = locationParams.a;
    return path + `?${qs.stringify(query)}`;
  }

  return uri;
}

export const addHeaderToOptions = (options: RequestInit) => (name: string, value: string) => {
  if (!options.headers) {
    options.headers = { [name]: value };
  } else if (options.headers instanceof Headers) {
    options.headers.append(name, value);
  } else if (Array.isArray(options.headers)) {
    options.headers.push([name, value]);
  } else {
    options.headers[name] = value;
  }
}


// Patches fetch function to make Apollo compatible with Wrench
// TODO: Make Wrench compliant with the standard graphQL format
export default async function fetchPatch(uri: string, options: RequestInit) {
  const config = await getConfig();
  if (config === undefined) return;
  uri = appendAnonContext(uri);

  const addHeader = addHeaderToOptions(options);

  if (config.csrf) {
    addHeader(config.csrf.headerName, config.csrf.token);
  }

  if (window.location.pathname.startsWith('/app/admin/')) {
    addHeader(requestAsHeader, requestAsTenantAdmin);
  }

  if (typeof options.body === 'string') {
    const data = JSON.parse(options.body);
    options.body = data.query;
  }

  const response = await fetch(uri, options);
  const text = (await response.text()) || '[]';

  response.text = async () => {
    return `{"data": ${text}}`;
  }

  return response;
};
