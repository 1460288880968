import config from './config';
import debug from './debug';
import permissions from './permissions';
import session from './session';
import { StateContainer } from './state';

function mergeContainers(...containers: StateContainer[]): StateContainer {
  const result: StateContainer = {
    defaults: {},
    resolvers: {
      Mutation: {},
    },
  };

  containers.forEach(container => {
    Object.entries(container.defaults).forEach(([name, def]) => {
      result.defaults[name] = def;
    });

    if(container.resolvers.Mutation) {
      Object.entries(container.resolvers.Mutation).forEach(([name, resolver]) => {
        if(!result.resolvers.Mutation) return;
        result.resolvers.Mutation[name] = resolver;
      });
    }
  });

  return result;
}

const clientState = mergeContainers(debug, config, permissions, session);
export default clientState;
