import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import Item from './DebugBarItem';
import DebugExpireSession from './DebugExpireSession';
import DebugForceRole from './DebugForceRole';
import { containerClass } from './styles';

const GET_DEBUGBAR_STATE = gql`
  query {
    debug @client {
      enabled
    }
  }
`;

interface Data {
  debug: {
    enabled: boolean;
  }
};

interface Props {};
class DebugBarQuery extends Query<Data> {};
class DebugBar extends React.PureComponent<Props> {
  render() {
    return (
      <DebugBarQuery query={GET_DEBUGBAR_STATE}>
        {({ loading, data }) => {
          if(loading || !data || !data.debug.enabled) return null;

          return (
            <div className={containerClass}>
              <Item>
                Force role on all locations:&nbsp;
                <DebugForceRole/>
              </Item>
              <Item>
                <DebugExpireSession/>
              </Item>
            </div>
          );
        }}
      </DebugBarQuery>
    );
  }
}

export default DebugBar;
