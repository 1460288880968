import gql from 'graphql-tag';
import { Role } from 'types';
import { StateContainer } from './state';

const GET_DEBUG = gql`
  {
    debug @client {
      enabled
      forcedRole
    }
  }
`;

interface Data {
  debug: {
    enabled: boolean;
    forcedRole: Role | null;
  }
};

function genState(enabled: boolean, forcedRole: Role | null) {
  return {
    enabled,
    forcedRole,
    __typename: 'debug',
  };
}

const debugBar: StateContainer<Data> = {
  defaults: {
    debug: genState(false, null),
  },
  resolvers: {
    Mutation: {
      toggleDebugBarVisibility(_, _vars, { cache }) {
        const result: Data = (cache.readQuery({ query: GET_DEBUG }) as any);
        cache.writeData({
          data: {
            debug: genState(!result.debug.enabled, result.debug.forcedRole),
          },
        });
        return null;
      },
      setDebugForcedRole(_, { role }, { cache }) {
        const result: Data = (cache.readQuery({ query: GET_DEBUG }) as any);
        cache.writeData({
          data: {
            debug: genState(result.debug.enabled, role),
          },
        });
        return null;
      },
    },
  },
};

export default debugBar;
