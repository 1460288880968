import classNames from 'classnames';
import React from 'react';

interface ModalHeaderProps {
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
}
export default class ModalHeader extends React.PureComponent<ModalHeaderProps> {
  render() {
    const { className, textAlign } = this.props;
    const fullClassName = classNames(
      'opux-modal-title',
      className,
      {
        'opux-align-left': textAlign === 'left',
        'opux-align-center': textAlign === 'center',
        'opux-align-right': textAlign === 'right',
      }
    );

    return (
      <div className={fullClassName}>{ this.props.children }</div>
    );
  }
}
