import React from 'react';

interface Props {
  as?: React.ComponentType<any>;
  minWidth?: number;
  maxWidth?: number;
  onUpdate?: (width: number) => void;
  fireOnMount?: boolean;
};
interface State {
  width: number;
}
export default class Responsive extends React.PureComponent<Props, State> {
  static onlyMobile = {
    minWidth: undefined,
    maxWidth: 767,
  };

  static onlyTablet = {
    minWidth: 768,
    maxWidth: 979,
  };

  static onlyComputer = {
    minWidth: 980,
    maxWidth: undefined,
  };

  state: State = {
    width: document.body.clientWidth,
  };

  handleResize = () => {
    this.setState({ width: document.body.clientWidth });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if(this.props.onUpdate && this.state.width !== prevState.width) {
      this.props.onUpdate(this.state.width);
    }
  }

  componentDidMount() {
    const { fireOnMount, onUpdate } = this.props;
    window.addEventListener('resize', this.handleResize);

    if(fireOnMount && onUpdate) {
      onUpdate(this.state.width);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const {
      as,
      minWidth = 0,
      maxWidth = Infinity,
      onUpdate,
      fireOnMount,
      ...restProps
    } = this.props;
    const { width } = this.state;

    const Component = as ? as : React.Fragment;
    if(width >= minWidth && width <= maxWidth) {
      return (
        <Component { ...restProps }/>
      )
    }

    return null;
  }
}
