import classNames from 'classnames';
import * as csx from 'csx';
import React from 'react';
import ReactModal from 'react-modal';
import 'styles/modal.css';
import { style } from 'typestyle';
import * as CommonButtons from './ModalCommonButtons';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalSubHeader from './ModalSubHeader';

const modalBgClass = style({
  backgroundColor: csx.important(csx.rgba(0, 0, 0, 0.5).toString()),
  opacity: csx.important(1) as any,
});

interface ModalProps {
  open: boolean;
  derenderOnClose?: boolean;
  onClose?: () => void;
  onClick?: (e: React.MouseEvent) => void;
};
export default class Modal extends React.PureComponent<ModalProps> {
  static Header = ModalHeader;
  static SubHeader = ModalSubHeader;
  static Content = ModalContent;
  static Footer = ModalFooter;
  static CommonButtons = CommonButtons;

  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if(this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const { open, derenderOnClose = true, onClose } = this.props;

    return (
      // @ts-ignore
      <ReactModal
        htmlOpenClassName='opux-modal-open'
        isOpen={open || !derenderOnClose}
        onRequestClose={onClose}
        portalClassName={classNames('oprh-modal-portal', { hidden: !open && !derenderOnClose })}
        className={classNames('opux-modal', 'opux-is-visible')}
        overlayClassName={classNames('opux-modal-bg', modalBgClass)}
      >
        <div className='opux-modal-inner' onClick={this.handleClick}>
          <div className='opux-modal-content'>
            { this.props.children }
          </div>
        </div>
      </ReactModal>
    );
  }
}
