import { addHeaderToOptions, appendAnonContext } from 'apollo/fetchPatch';
import { baseUrl, graphqlPath } from 'const';
import { getConfig } from 'globalConfig';

export interface Options {
  data?: object | string;
  rootPath?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: { [name: string]: string };
  parseResponse?: boolean;
};
export default async function restFetch(
  url: string,
  { data, rootPath = '/rest/api', method = 'GET', parseResponse = true, headers = {} }: Options
) {
  const config = await getConfig();
	if (config===undefined) return;
  url = appendAnonContext(url);
  const options: RequestInit = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
      ...headers,
    },
  }

  const addHeader = addHeaderToOptions(options);


  if(config.csrf) {
		addHeader(config.csrf.headerName, config.csrf.token);
  }

  if(data) {
    options.body = data instanceof Object ? JSON.stringify(data) : data;
    if(options.method === 'GET') {
      options.method = 'POST';
    }
  }

  if(rootPath.endsWith('/') && url.startsWith('/')) {
    rootPath = rootPath.slice(0, -1);
  }

  const restUrl = `${baseUrl}${rootPath}${url}`;
  const response = await window.fetch(restUrl, options);
  if(!parseResponse) return response;
  if(!response.ok) {
    if (response.status == 404) {
      // Not found
      console.warn(`${restUrl} not found`);
      return null;
    }
    if (response.status == 401 || response.status == 403) {
      // Not found
      console.warn(`${restUrl} not permission`);
      return null;
    }
    throw new Error(`GraphQL restFetch ${restUrl} failed. Status ${response.status}`, {cause: response.json()});
  }
  return response.json();
}

export function fetchGraphQL(query: string) {
  return restFetch(graphqlPath, { data: query });
}

export interface LocationUser {
  id: number;
  firstName: string;
  lastName: string;
  userId: string;
  active: boolean;
}

export function fetchLocationUsers(
  locationId: string | number,
  resource: string
): Promise<LocationUser[]> {
  return restFetch(
    `/user/findbylocation/${locationId}?assignableTo=${resource}`,
    { rootPath: '/' }
  );
}
