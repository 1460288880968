import { Loading } from '@resys/opux-react';
import gql from 'graphql-tag';
import React, { lazy, Suspense } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { Switch } from 'react-router-dom';
import TermsAndConditionsPage from 'views/TermsAndConditionsPage';
import PageRoute from './PageRoute';

const AdminRoutes = lazy(() => import('./AdminRoutes'));
const SysAdminRoutes = lazy(() => import('./SysAdminRoutes'));
const SigninRoutes = lazy(() => import('./SigninRoutes'));
const AnonymousRoutes = lazy(() => import('./AnonymousRoutes'));
const MainRoutes = lazy(() => import('./MainRoutes'));

const GET_USER_INFO = gql`
  {
    termsAccepted @client
  }
`

interface Props {};
const AppMainContent: React.FC<Props> = () => {
  const { data: userData, loading, error } = useQuery(GET_USER_INFO);

  if(loading || error) {
    return <Loading/>
  }

  if(userData && !userData.termsAccepted) {
    return <TermsAndConditionsPage/>
  }

  return (
    <Suspense fallback={<Loading/>}>
      <Switch>
        <PageRoute path='/admin' component={AdminRoutes}/>
        <PageRoute path='/sysadmin' component={SysAdminRoutes}/>
        <PageRoute path='/signin' component={SigninRoutes}/>
        <PageRoute path='/anon' component={AnonymousRoutes}/>
        <PageRoute path='/' component={MainRoutes}/>
      </Switch>
    </Suspense>
  );
};

export default AppMainContent;
