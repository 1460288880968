import React from 'react';
import { style } from 'typestyle';

const iconsClass = style({
  display: 'inline-block',
  marginLeft: 10,
});

interface Props {};
const PageIcons: React.FC<Props> = ({ children }) => (
  <div className={iconsClass}>
    { children }
  </div>
);

export default PageIcons;
