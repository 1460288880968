import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { Role } from 'types';
import isAnonymous from 'util/isAnonymous';
import buildPermissions from './buildPermissions';
import { flatPermissionsToMap } from './flatPermissionsToMap';
import { FlatPermission } from './permissionTypes';

const GET_PERMISSIONS = gql`
  query {
    configInitialized @client
    isAdmin @client
    systemAdmin @client
    tenantAdmin @client
    userRestId @client
    debug @client {
      forcedRole
    }
    permissions @client {
      locationId
      resource
      createPermission
      updatePermission
      deleteAllPermission
      deleteSelfPermission
    }
  }
`;

interface Data {
  configInitialized: boolean;
  isAdmin: boolean;
  systemAdmin: boolean;
  tenantAdmin: boolean;
  userRestId: number | null;
  debug: {
    forcedRole: Role | null;
  };
  permissions: FlatPermission[];
};

export default function usePermissions() {
  const { data, error, loading } = useQuery<Data>(GET_PERMISSIONS);

  if(error) {
    return 'Error';
  }

  if(loading || !data || !data.configInitialized) {
    return undefined;
  }

  const permissions = flatPermissionsToMap(data.permissions);
  const result = buildPermissions(
    data.debug.forcedRole ? false : data.isAdmin,
    data.debug.forcedRole ? false : data.systemAdmin,
    data.debug.forcedRole ? false : data.tenantAdmin,
    isAnonymous(),
    data.debug.forcedRole
      ? {} // TODO
      : permissions,
    data.userRestId
  );
  return result;
}
